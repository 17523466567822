document.addEventListener('DOMContentLoaded', (event) => {
    const menuToggles = document.querySelectorAll('.menu-toggle, .toggle-open, .toggle-close');
    const mobileContainer = document.querySelector('.mobile.container');

    menuToggles.forEach(toggle => {
        toggle.addEventListener('click', () => {
            mobileContainer.classList.toggle('active');
        });
    });
});
